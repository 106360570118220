import React from 'react';
import LoginForm from '../login-form';

const LoginPage = () => {
    return (
      <div className='login-form-holder' style={{padding: "20px 0"}}>
        <LoginForm />
      </div>
    )
}

export default LoginPage;
