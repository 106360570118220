import React, {useState, useEffect} from 'react';
import Ticker from 'react-ticker';
import StatistikService from '../../services/statistik-service';

import './style.scss';

const TickerLine = ({tables}) => {
  return (
    <div className='ticker-holder'>
      <Ticker offset="run-in" speed={10} mode="await">
        {() => <GetDataFromAPI />}
      </Ticker>
    </div>
  )
}

const GetDataFromAPI = () => {
  const statistikService = new StatistikService();
  const [data, setData] = useState("");

  useEffect(() => {
    async function fetchData() {
      await statistikService.getTickerText();
      const message = localStorage.getItem('message');
      const carouselImages = JSON.parse(localStorage.getItem('carouselImages'));
      setData({
        message: message,
        carouselImages: carouselImages
      });
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data.message ? (
    <p style={{ whiteSpace: "nowrap" }}>{data.message.replaceAll(" ", '\u00A0')}</p>
  ) : (
    <p style={{ visibility: "hidden" }}>Placeholder</p>
  );
};

export default TickerLine;
