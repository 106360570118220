import React from 'react';
import Header from '../header';

const TemplatePage = ({children}) => {
    return (
      <>
        <Header />
        {children}
      </>
    )
}

export default TemplatePage;

