import React, {Component} from 'react';
import {Navigate} from 'react-router-dom';

export default class VerifyLoggedIn extends Component {
  render() {
      // console.log('VerifyLoggedIn render');
    let pathname = window.location.pathname;

    if (!this.props.loggedIn && (pathname !== '/login')) {
      return <Navigate to="/login" />
    }

    return this.props.children;
  }
}
