import React, {Component} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useNavigate} from "react-router";
import {connect} from 'react-redux';
import {loginUser, logoutUser} from '../../actions';
import {LoginPage, NotFoundPage, SettingsPage, StatistikPage} from '../pages';
import StatistikService from '../../services/statistik-service';
import VerifyLoggedIn from '../verifyLoggedIn';

import './style.scss';

class App extends Component {
  statistikService = new StatistikService();

  componentDidMount = () => {
    this.checkUserLogin();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser.token !== this.props.currentUser.token) {
      this.checkUserLogin();
    }

    // scroll to top after change page
    const locationChanged = this.props.location !== prevProps.location;

    if (locationChanged) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  checkUserLogin = async () => {
    const token = localStorage['auth-token'];
    if (token) {
      await this.statistikService.getUser(token)
      .then(res => {
        this.props.loginUser(res);
      })
      .catch(err => {
        console.log('Get user error', err);
        this.props.logoutUser();
      })
    }
  }

  render() {
      // console.log('app render');
    const {currentUser} = this.props;
    const loggedIn = !!currentUser;

    return (
      <VerifyLoggedIn loggedIn={loggedIn}>
        <Routes>
          <Route path='/' element={<StatistikPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/settings' element={<SettingsPage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </VerifyLoggedIn>
    )
  }
}

const withRouter = (Component) => {
  return (props) => {
      const history = useNavigate();
      return <Component history={history} {...props} />;
    };
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser
  }
}

const mapDispatchToProps = {
  loginUser,
  logoutUser
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
// export default App;
