import React from 'react';
import TemplatePage from './page-template';
import Account from '../account';

const SettingsPage = () => {
    return (
      <TemplatePage>
        <Account />
      </TemplatePage>
    )
}

export default SettingsPage;
