const initialState = {
  currentUser: false,
  counter: 0
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_USER':
      return {
        ...state, 
        currentUser: action.payload ? {...state.currentUser, ...action.payload} : true
      };
    case 'LOGOUT_USER':
      localStorage.removeItem("auth-token");
      return {
        ...state, 
        currentUser: false 
      };
    case 'INCREASE_COUNTER':
      return {
        ...state, 
        counter: state.counter + 1 
      };
    case 'DECREASE_COUNTER':
      return {
        ...state, 
        counter: state.counter - 1 
      };
    default:
      return state;
  }
}

export default reducer;