import React, { useEffect, useState } from 'react';
import Clock from 'react-live-clock';

function SV_Clock() {
    const locale = 'sv-SE';
    const timezone = 'Europe/Stockholm';

    // const [value, setValue] = useState(new Date());
    const [endmonth, setEndmonth] = useState(new Date());

    // const hours_to_end_of_month = false;


    useEffect(() => {
        // const interval = setInterval(() => setValue(new Date()), 1000);

        const intervalmonth = setInterval(() => {

            let date = new Date(), y = date.getFullYear(), m = date.getMonth();
            let today = new Date();
            let lastDay = new Date(y, m + 1, 1);

            let distance = parseInt(lastDay.getTime()-today.getTime());
            let diff = parseInt(Math.abs(distance / 3600000));
            if (diff > 72) {
                diff = '';
            } else {
                diff = 'Time to end of month: ' + diff + 'h';
            }

            setEndmonth(diff);
        }, 1000);

        return () => {
            // clearInterval(interval);
            clearInterval(intervalmonth);
        };
    }, []);
    //<>{endmonth}</>
    return (
        <div>
            <Clock format={'HH:mm:ss'} ticking={true} timezone={timezone} locale={locale} />
        </div>
    );
}

export default SV_Clock;