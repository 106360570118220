import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loginUser, logoutUser} from '../../actions';
import StatistikService from '../../services/statistik-service';

import './style.scss';

class Account extends Component {
    statistikService = new StatistikService();
    state = {
        image: '',
        message: '',
        submitButtonText: 'Update',
        submitButtonState: false,
        formError: false,
        image1: null,
        image2: null,
        image3: null,
        imageEnabled1: 0,
        imageEnabled2: 0,
        imageEnabled3: 0,
    }

    componentDidMount = () => {
        this.refreshImages();
    }
    
    refreshImages = () => {
        const carouselImages = JSON.parse(localStorage.getItem('carouselImages'));
        const image_0 = carouselImages.find(x => x.key == 'image_0') || {};
        const image_1 = carouselImages.find(x => x.key == 'image_1') || {};
        const image_2 = carouselImages.find(x => x.key == 'image_2') || {};

        this.statistikService.getUser(localStorage.getItem('auth-token')).then(currentUser => {
            this.setState({
                message: localStorage.getItem('message'),
                image1: image_0 ? image_0.image : null,
                image2: image_1 ? image_1.image : null,
                image3: image_2 ? image_2.image : null,
                imageEnabled1: image_0.is_shown ? 1 : 0,
                imageEnabled2: image_1.is_shown ? 1 : 0,
                imageEnabled3: image_2.is_shown ? 1 : 0,
            });
        })
    }

    inputChangeHandler = (e) => {
        let input = e.target,
            type = input.type,
            name = input.name,
            value = input.value;

        if (type === 'file') {
            this.setState({
                [name]: input.files[0]
            });
        } else if (type === 'checkbox') {
            if (!e.target.checked) {
                value = false;
            } else {
                value = 1;
            }
            this.setState({
                [name]: value
            });
        } else {
            // this.statistikService.userUpdateMessage(value)
            this.setState({
                [name]: value
            });
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            submitButtonText: 'Updating...',
            submitButtonState: true,
            loading: true
        });
        const {
            image,
            message,
            image1,
            image2,
            image3,
            imageEnabled1,
            imageEnabled2,
            imageEnabled3,
        } = this.state;
        const formData = new FormData();
        formData.append("image", image);
        formData.append("message", message);


        if (image1) {
            if (typeof image1 == 'object') {
                formData.append("images[0][image]", image1);
            }

            formData.append("images[0][enabled]", imageEnabled1);
            formData.append("images[0][key]", 'image_0');
        }

        if (image2) {
            if (typeof image2 == 'object') {
                formData.append("images[1][image]", image2);
            }

            formData.append("images[1][enabled]", imageEnabled2);
            formData.append("images[1][key]", 'image_1');
        }

        if (image3) {
            if (typeof image3 == 'object') {
                formData.append("images[2][image]", image3);
            }

            formData.append("images[2][enabled]", imageEnabled3);
            formData.append("images[2][key]", 'image_2');
        }

        e.target.reset();
        await this.statistikService.userUpdateMessage(formData).then(res => {
            this.setState({
                submitButtonText: 'Updated!',
                submitButtonState: false,
                loggedIn: true,
                loading: false
            });
            setTimeout(() => {
                this.setState({
                    submitButtonText: 'Update',
                });
            }, 1500);
            return res
        });

        this.statistikService.getTickerText()
            .then(res => {
                this.refreshImages();
            })
    }

    deleteImage = async (imageNumber) => {
        const carouselImages = JSON.parse(localStorage.getItem('carouselImages'));
        const key = 'image_' + (parseInt(imageNumber) - 1);
        const image = carouselImages.find(x => x.key == key) || {};

        if (image) {
            console.log(image);
            await this.statistikService.removeImage(image.id)
        }

        switch (imageNumber) {
            case 1: {
                this.setState({image1: null, imageEnabled1: false})
                break;
            }
            case 2: {
                this.setState({image2: null, imageEnabled2: false})
                break;
            }
            case 3: {
                this.setState({image3: null, imageEnabled3: false})
                break;
            }
            default: break;
        }
    }

    render() {
        const {currentUser} = this.props;
        const {
            loading,
            message,
            submitButtonText,
            submitButtonState,
            formError,
            image1,
            image2,
            image3,
            imageEnabled1,
            imageEnabled2,
            imageEnabled3,
        } = this.state;

        const date = Date.now();
        return (
            <div className='container'>
                <div className="account-holder">
                    <div className='account'>
                        <form name="account-form" onSubmit={this.handleSubmit}>
                            <div className="form-inner">
                                <div className='form-heading'>
                                    <h2>Hi {currentUser.username}!</h2>
                                </div>
                                {
                                    currentUser.logo
                                        ?
                                        <div className="form-outline">
                                        </div>
                                        : ''
                                }
                                <div className="form-outline account-image-block">
                                    <img src={currentUser.logo} alt={currentUser.username}/>

                                </div>
                                <div className="form-outline">
                                    <textarea name="message" value={message} onChange={this.inputChangeHandler}/>
                                </div>
                                <div className="form-outline">
                                    <fieldset>
                                        <legend>Image 1</legend>
                                        {image1 && typeof image1 != 'object' &&
                                            <span className="image-area">
                                            <img src={image1 + "?time=" + date} alt="" width="250" height="250"/>
                                                <button type="button" onClick={async () => {await this.deleteImage(1) }} className="remove-image" style={{display: "inline"}}>&#215;</button>
                                            </span>
                                        }
                                        <input type={"file"} name={"image1"} placeholder="image1" onChange={this.inputChangeHandler} />
                                        <label>Enabled: <input disabled={!image1} type={"checkbox"} name={"imageEnabled1"} checked={imageEnabled1 ? 'checked' : false} onChange={this.inputChangeHandler} /></label>
                                    </fieldset>
                                </div>
                                <div className="form-outline">
                                    <fieldset>
                                        <legend>Image 2</legend>
                                        {image2 && typeof image2 != 'object' &&
                                            <span className="image-area">
                                            <img src={image2 + "?time=" + date} alt="" width="250" height="250"
                                                 className=""/>
                                                <button type="button" onClick={async () => {await this.deleteImage(2) }} className="remove-image" style={{display: "inline"}}>&#215;</button>
                                            </span>
                                        }
                                        <input type={"file"} name={"image2"} placeholder="image2" onChange={this.inputChangeHandler} />
                                        <label>Enabled: <input disabled={!image2} type={"checkbox"} name={"imageEnabled2"} checked={imageEnabled2 ? 'checked' : false} onChange={this.inputChangeHandler} /></label>
                                    </fieldset>
                                </div>
                                <div className="form-outline">
                                    <fieldset>
                                        <legend>Image 3</legend>
                                        {image3 && typeof image3 != 'object' &&
                                            <span className="image-area">
                                            <img src={image3 + "?time=" + date} alt="" width="250" height="250"
                                                 className=""/>
                                                <button type="button" onClick={async () => {await this.deleteImage(3) }} className="remove-image" style={{display: "inline"}}>&#215;</button>
                                            </span>
                                        }
                                        <input type={"file"} name={"image3"} placeholder="image3" onChange={this.inputChangeHandler} />
                                        <label>Enabled: <input disabled={!image3} type={"checkbox"} name={"imageEnabled3"} checked={imageEnabled3 ? 'checked' : false} onChange={this.inputChangeHandler} /></label>
                                    </fieldset>
                                </div>
                                {formError ? formError : ''}
                                <div className='btn-holder'>
                                    <button className={loading ? "btn-submit disable" : "btn-submit"} type="submit"
                                            disabled={submitButtonState}>{submitButtonText}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser
    }
}

const mapDispatchToProps = {
    loginUser,
    logoutUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
