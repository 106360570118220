import React, {Component} from 'react';
import {Navigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {loginUser, logoutUser} from '../../actions';
import StatistikService from '../../services/statistik-service';

import './style.scss';

class LoginForm extends Component {
    statistikService = new StatistikService();
    state = {
        username: '',
        password: '',
        formError: false,
        userOfficeId: false,
        admin: false,
        loggedIn: false
    }

    componentDidMount() {
        this.checkUserLogin();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentUser !== this.props.currentUser) {
            this.checkUserLogin();
        }
    }

    checkUserLogin = () => {
        if (this.props.currentUser) {
            this.setState({
                loggedIn: true
            })
        }
    }

    inputChangeHandler = (e) => {
        const name = e.target.name,
            value = e.target.value;
        this.setState({
            [name]: value
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const {username, password} = this.state;
        const userObj = {
            email: username,
            password: password
        };
        await this.statistikService.userLogin(userObj)
            .then(res => {
                localStorage.setItem('currentUser', JSON.stringify(res.user));
                this.setState({
                    loggedIn: true,
                    admin: res.user.is_admin,
                    loading: false
                }, () => {
                    localStorage.setItem("auth-token", res.token);
                    this.props.loginUser({
                        username: username,
                        token: res.token,
                        email: res.user.email,
                    });
                });
                return res
            })
            .catch(async (err) => {
                if (err.errors) {
                    if (err.errors.email) {
                        this.setState({
                            formError: err.errors.email[0] || 'Whoops! Something went wrong...',
                            loading: false
                        })
                    }
                } else {
                    if (err.message) {
                        this.setState({
                            formError: err.message,
                            loading: false
                        })
                    }
                }
            });
    }

    render() {
        const {
            loading,
            username,
            password,
            loggedIn,
            formError
        } = this.state;

        if (loggedIn) {
            return <Navigate to="/"/>
        }

        return (
            <div className="login-holder">
                <form name="signin-form" onSubmit={this.handleSubmit}>
                    <div className="form-inner">
                        <div className='form-heading'>
                            <h2>Login</h2>
                            <p>Please enter your username and password!</p>
                        </div>
                        <div className="form-outline">
                            <input
                                type="text"
                                name="username"
                                className="input"
                                value={username}
                                onChange={this.inputChangeHandler}
                                placeholder="Username"
                                required="required"
                            />
                        </div>
                        <div className="form-outline">
                            <input
                                type="password"
                                name="password"
                                id="user_pass"
                                className="input"
                                autoComplete="password"
                                value={password}
                                onChange={this.inputChangeHandler}
                                placeholder="Password"
                                required="required"
                            />
                        </div>
                        {formError ? formError : ''}
                        <div className='btn-holder'>
                            <button className={loading ? "btn-submit disable" : "btn-submit"} name="login-form"
                                    value="login-form" type="submit">Login
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser
    }
}

const mapDispatchToProps = {
    loginUser,
    logoutUser
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
