import React from 'react';
import TemplatePage from './page-template';

const NotFoundPage = () => {
    return (
      <TemplatePage>
        <div className='container'>
          <div className="not-found">
            <div className="head">
              <h1>Not Found</h1>
            </div>
            <div className="content">
              <p>Sorry, but you are looking for something that isn't here.</p>
            </div>
          </div>
        </div>
      </TemplatePage>
    )
}

export default NotFoundPage;
