import React from 'react';

import './style.scss';

const StatistikWrapper = ({tables}) => {
    let tablestyle = {};
    if (!tables.tableSm || typeof tables.tableSm === undefined) {
        tablestyle = {width:'100%'};
    }
    if (tables.customWidth) {
        tablestyle = {width: `${tables.customWidth}%`};
    }
  return (
    <section className="statistik-wrapper">
      <div className="container">
        <div className="table-row">
          <div className='table-col-lg' style={tablestyle} >{tables.tableLg}</div>
          <div className='table-col-sm'>{tables.tableSm}</div>
        </div>
      </div>
    </section>
  )
}

export default StatistikWrapper;
