const loginUser = userObj => ({
  type: 'LOGIN_USER',
  payload: userObj
})

const logoutUser = () => {
return {
  type: 'LOGOUT_USER'
}
}

const increase = () => {
  return {
    type: 'INCREASE_COUNTER'
  }
}

const decrease = () => {
  return {
    type: 'DECREASE_COUNTER'
  }
}

export {
loginUser,
logoutUser,
increase,
decrease
}