import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {logoutUser} from '../../actions';
import StatistikService from '../../services/statistik-service';
import {FaCog, FaSignOutAlt} from "react-icons/fa";
import SV_Clock from '../clock';

import './style.scss';

class Header extends Component {
  statistikService = new StatistikService();

  sendLogout = async (e) => {
    if (e) {
      e.preventDefault();
    }

    await this.statistikService.userLogout()
    .then(res => {
      if (res.revoked) {
        this.props.logoutUser();
      }
    })
    .catch(err => {
      console.log('err', err)
    });
  }

  render() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};

    let settings_link = null;
    if(currentUser.is_admin){
      settings_link = <li>
        <Link to='/settings'><FaCog /></Link>
      </li>;
    }
    return (
      <>
        <header id="header" className="header">
          <div className="container">
            <div className="header-holder">
              <strong className="logo">
                <Link to='/'>
                  <img
                    src="/logo-smh.svg"
                    className="custom-logo"
                    alt="Statistik"/>
                </Link>
              </strong>
              <ul className='nav'>
                <li>
                  <SV_Clock/>
                </li>
                {settings_link}
                <li>
                  <Link to="/" onClick={this.sendLogout}><FaSignOutAlt /></Link>
                </li>
              </ul>
            </div>
          </div>
        </header>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser
  }
}

const mapDispatchToProps = {
  logoutUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
