import React from 'react';
import StatistikService from '../../services/statistik-service';

import './error-message.scss';

const ErrorMessage = () => {
    console.log('error occurred')
    const statistikService = new StatistikService()
    statistikService.storageCleanup()

    setTimeout(()=>{
        window.location.reload();
    },10000);
  return (
    <div className="error-block">
      <div className="container">
        <h2>Connection interrupted.</h2>
        <p>Please reload the page or try logout and login.</p>
      </div>
    </div>
  )
}

export default ErrorMessage;
